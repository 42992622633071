import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    target: "_blank",
    href: _ctx.href,
    class: "social-link hover:opacity-70 flex items-center justify-center p-6"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}