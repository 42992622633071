
import {defineComponent} from "vue"

export default defineComponent({
    props: {
        href: {
            type: String,
            required: true
        }
    }
})
