<template>
    <svg width="255" height="61" viewBox="0 0 255 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_42_488" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="255" height="61">
            <path d="M0 7.83099C0 4.465 2.81644 1.64856 6.18243 1.64856C9.54842 1.64856 12.3649 4.465 12.3649 7.83099V25.554H32.1486V7.83099C32.1486 4.465 34.9651 1.64856 38.3311 1.64856C41.6971 1.64856 44.5135 4.465 44.5135 7.83099V54.8175C44.5135 58.1835 41.6971 60.9999 38.3311 60.9999C34.9651 60.9999 32.1486 58.1835 32.1486 54.8175V37.0945H12.3649V54.8175C12.3649 58.1835 9.54842 60.9999 6.18243 60.9999C2.81644 60.9999 0 58.1835 0 54.8175V7.83099Z" fill="#303030"/>
            <path d="M90.9499 19.2343C84.8362 22.2568 80.7833 28.508 80.7833 36.6138V41.9719C80.7833 47.33 77.486 50.2152 73.3644 50.2152C69.2427 50.2152 65.9454 47.33 65.9454 41.9719V23.0125C65.9454 19.8525 63.4038 17.2422 60.1752 17.2422C56.9466 17.2422 54.4049 19.8525 54.4049 23.0125V41.5598C54.4049 53.169 62.7168 60.9314 73.3644 60.9314C76.593 60.9314 79.5468 60.2444 82.2259 58.9393C88.2709 55.9167 92.3238 49.6656 92.3238 41.5598V23.0125C92.3238 21.5699 91.7743 20.2647 90.9499 19.2343Z" fill="#303030"/>
            <path d="M99.7433 17.2422C96.5147 17.2422 93.5608 17.9291 90.9505 19.2343C84.8367 22.2568 80.7838 28.508 80.7838 36.6138V55.1611C80.7838 56.6037 81.3334 57.9089 82.2264 58.9393C88.2714 55.9167 92.3243 49.6656 92.3243 41.5598V36.2016C92.3243 30.8435 95.6217 27.9584 99.7433 27.9584C103.865 27.9584 107.162 30.8435 107.162 36.2016V55.1611C107.162 58.3897 109.704 60.9314 112.932 60.9314C116.161 60.9314 118.703 58.3897 118.703 55.1611V36.6138C118.703 25.0046 110.391 17.2422 99.7433 17.2422Z" fill="#303030"/>
            <path d="M123.649 23.4929C123.649 20.5391 126.053 18.1348 129.007 18.1348H130.587V13.1888C130.587 9.96025 133.128 7.41858 136.357 7.41858C139.586 7.41858 142.127 9.96025 142.127 13.1888V18.1348H150.645C153.599 18.1348 156.003 20.5391 156.003 23.4929C156.003 26.4467 153.599 28.851 150.645 28.851H142.127V40.1168C142.127 46.9861 145.905 50.2834 150.645 50.2834C153.599 50.2834 156.003 52.6877 156.003 55.6416C156.003 58.5954 153.599 60.9997 150.645 60.9997C139.517 60.9997 130.655 52.8938 130.655 40.1855V28.9197H129.075C126.122 28.9197 123.717 26.5154 123.717 23.5616L123.649 23.4929Z" fill="#303030"/>
            <path d="M166.307 46.0248C167.818 46.0248 168.849 46.5057 169.742 47.2613C171.459 48.6352 172.695 50.6273 175.375 50.6273C176.954 50.6273 178.26 49.3221 178.26 47.7422C178.26 45.1318 175.375 44.3075 172.009 43.1397C167.2 41.4911 161.361 39.2929 161.361 30.3627C161.361 23.2185 167.475 17.2422 175.375 17.2422C182.381 17.2422 189.388 21.2951 189.388 26.035C189.388 28.9888 186.984 31.3931 184.03 31.3931C183.068 31.3931 182.313 31.1183 181.557 30.8435C179.427 29.7444 177.916 27.5462 175.375 27.5462C173.795 27.5462 172.489 28.8514 172.489 30.4314C172.489 32.767 175.443 33.4539 178.947 34.6217C183.893 36.2016 189.8 38.6059 189.8 47.33C189.8 54.7489 183.48 60.9314 175.375 60.9314C167.887 60.9314 160.949 56.3289 160.949 51.383C160.949 48.4291 163.353 46.0248 166.307 46.0248Z" fill="#303030"/>
            <path d="M198.044 7.41883C198.044 4.19023 200.586 1.64856 203.815 1.64856C207.043 1.64856 209.585 4.19023 209.585 7.41883V55.2296C209.585 58.4582 207.043 60.9999 203.815 60.9999C200.586 60.9999 198.044 58.4582 198.044 55.2296V7.41883ZM212.814 43.3456C211.921 42.2465 211.234 40.804 211.234 39.1553C211.234 37.9188 211.783 36.2702 212.745 35.1711L226.209 19.1655C227.377 17.8603 228.613 17.242 230.193 17.242C233.353 17.242 235.689 19.4402 235.689 22.5314C235.689 23.7679 235.345 24.9357 234.452 25.9661L223.873 38.4684L234.727 51.3828C235.62 52.4819 236.101 53.8558 236.101 55.0923C236.101 58.3896 233.284 60.8625 230.262 60.8625C228.682 60.8625 226.964 60.1069 225.865 58.8017L212.745 43.2082L212.814 43.3456Z" fill="#303030"/>
            <path d="M241.047 6.59459C241.047 2.95383 244.001 0 247.641 0C251.282 0 254.236 2.95383 254.236 6.59459C254.236 10.2354 251.282 13.1892 247.641 13.1892C244.001 13.1892 241.047 10.2354 241.047 6.59459ZM241.871 23.0811C241.871 19.8525 244.413 17.3108 247.641 17.3108C250.87 17.3108 253.412 19.8525 253.412 23.0811V55.2297C253.412 58.4583 250.87 61 247.641 61C244.413 61 241.871 58.4583 241.871 55.2297V23.0811Z" fill="#303030"/>
        </mask>
        <g mask="url(#mask0_42_488)">
            <rect x="-44" y="-10" width="339" height="97" fill="url(#paint0_linear_42_488)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_42_488" x1="-9.00001" y1="-70.5" x2="334" y2="87" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF0000"/>
                <stop offset="1" stop-color="#78004F"/>
            </linearGradient>
        </defs>
    </svg>
</template>
