
import ReturnButton from "@/views/ProjectView/ReturnButton"
import router from "@/router"
import {defineComponent} from "vue"
import store, {ProjectItem, Url} from "@/store"
import {GithubLogo} from "@/components/@logos"
import {ComputerIcon} from "@/components/@icons"
import ProjectAttributes from "@/components/ProjectAttributes"

export default defineComponent({
    components: {
        ReturnButton,
        GithubLogo,
        ComputerIcon,
        ProjectAttributes
    },

    computed: {
        project(): ProjectItem {
            return store.getters.getProjects.filter(
                (project: ProjectItem) => project.id === parseInt(router.currentRoute.value.params.id as string)
            )[0]
        }
    },

    methods: {
        loadProjectIllustration(path: string) {
            return require(`@/assets/applications/${path}`)
        },

        openWindow(url: Url) {
            window.open(url, '_blank')
        }
    },
})
