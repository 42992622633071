
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import router from "@/router"

export default {
    components: {
        AppHeader,
        AppFooter
    },

    computed: {
        transitionName() {
            if (router.currentRoute.value.name === 'HomeView') {
                return 'slide-reverse'
            }

            return 'slide'
        }
    },

    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
