import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "overflow-x-hidden flex flex-col bg-white lg:bg-primary-background-color min-h-screen transition-colors flex flex-col items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader),
    _createVNode(_component_router_view, { class: "p-10 mb-auto" }, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: $options.transitionName,
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    }),
    _createVNode(_component_AppFooter)
  ]))
}