
import {defineComponent} from "vue"
import store from "@/store"
import GalleryApplication from "@/views/HomeView/ProjectGallery/GalleryApplication"
import GalleryDesign from "@/views/HomeView/ProjectGallery/GalleryDesign"
import GalleryHeading from "@/views/HomeView/ProjectGallery/GalleryHeading"

export type SelectedGallery = 'projects' | 'designs'

export default defineComponent({
    components: {
        GalleryApplication,
        GalleryHeading,
        GalleryDesign
    },

    data() {
        return {
            selectedGallery: 'projects' as SelectedGallery
        }
    },

    computed: {
        projects() {
            return store.getters.getProjects
        },

        designs() {
            return store.getters.getDesigns
        }
    },

    methods: {
        changeGallery(gallery: SelectedGallery) {
            this.selectedGallery = gallery
        },
    }
})
