<template>
    <svg viewBox="0 0 344 93" fill="none">
        <path d="M66.2324 31.6283H41.4909C37.5799 31.6283 34.434 34.7741 34.434 38.7702C34.434 42.7662 37.5799 45.9121 41.4909 45.9121H56.0297C52.2037 54.3293 44.5517 58.5804 36.8147 58.5804C25.0816 58.5804 15.8142 48.8878 15.8142 36.7296C15.8142 24.5715 25.0816 14.7939 36.8147 14.7939C42.7662 14.7939 47.5275 17.0895 51.3535 20.5754C53.224 22.4459 55.4346 23.5512 57.4751 23.5512C61.6412 23.5512 65.1271 20.1503 65.1271 15.9842C65.1271 14.2838 64.3619 12.2432 63.0866 10.7978C56.7949 4.33614 47.5275 0 36.8147 0C16.2393 0 0 16.2393 0 36.7296C0 57.22 16.2393 73.3743 36.8147 73.3743C57.3901 73.3743 72.8641 55.4346 73.2042 39.2803C73.3743 34.7741 70.2284 31.6283 66.2324 31.6283Z" fill="#DF4D67"/>
        <path d="M127.533 19.3853C124.302 19.3853 121.497 21.5108 120.561 24.4866C116.395 21.2557 111.209 19.3853 105.597 19.3853C91.2286 19.3853 80.4308 31.2884 80.4308 46.4224C80.4308 61.5563 91.2286 73.3744 105.597 73.3744C111.209 73.3744 116.395 71.589 120.561 68.2731C121.497 71.2489 124.302 73.3744 127.533 73.3744C131.529 73.3744 134.675 70.2286 134.675 66.2326V26.5271C134.675 22.5311 131.529 19.3853 127.533 19.3853ZM107.638 60.1109C100.411 60.1109 94.7996 54.3294 94.7996 46.4224C94.7996 38.5153 100.411 32.6487 107.638 32.6487C114.865 32.6487 120.391 38.5153 120.391 46.4224C120.391 54.3294 114.78 60.1109 107.638 60.1109Z" fill="#DF4D67"/>
        <path d="M154.061 0C150.15 0 146.919 3.14583 146.919 7.14188V66.2324C146.919 70.2284 150.15 73.3743 154.061 73.3743C157.972 73.3743 161.203 70.2284 161.203 66.2324V7.14188C161.203 3.14583 158.057 0 154.061 0Z" fill="#DF4D67"/>
        <path d="M180.587 0C176.591 0 173.445 3.14583 173.445 7.14188V66.2324C173.445 70.2284 176.591 73.3743 180.587 73.3743C184.583 73.3743 187.728 70.2284 187.728 66.2324V7.14188C187.728 3.14583 184.583 0 180.587 0Z" fill="#DF4D67"/>
        <path d="M245.29 64.277H245.375C246.14 63.0017 246.48 61.8114 246.48 60.7911C246.48 56.7951 243.419 53.9043 239.423 53.9043C236.702 53.9043 235.257 55.4347 233.217 57.2202C231.516 58.5805 228.965 60.1109 225.224 60.1109C217.827 60.1109 212.301 53.9893 212.301 46.4224C212.301 38.8554 217.912 32.6487 225.224 32.6487C226.33 32.6487 228.03 32.8188 228.795 33.1589L219.953 43.1915C218.848 44.4668 218.423 45.9972 218.423 47.5276C218.423 51.2686 221.398 53.9893 225.309 53.9893C227.265 53.9893 228.795 53.3092 230.156 51.6937L243.334 35.9646C244.78 34.2642 245.29 32.7338 245.29 30.9483C245.29 28.9078 244.354 26.9522 242.739 25.5069C239.848 22.7861 233.557 19.3853 225.224 19.3853C209.41 19.3853 197.932 31.2884 197.932 46.4224C197.932 61.5563 209.495 73.3744 225.224 73.3744C231.686 73.3744 237.128 71.3339 240.954 68.6132L245.29 64.277Z" fill="#DF4D67"/>
        <path d="M281 19.3853C274.708 19.3853 269.012 21.4258 264.676 25.2518L262.295 27.6324L261.87 28.1426C258.384 32.3937 256.259 38.2602 256.259 45.147V53.2241L273.263 36.0496L273.858 35.4545C275.729 33.584 278.279 32.6487 281 32.6487C284.656 32.6487 287.632 29.673 287.632 26.017C287.632 22.361 284.656 19.3853 281 19.3853Z" fill="#DF4D67"/>
        <path d="M337.198 19.3853C335.243 19.3853 333.712 20.0654 332.267 21.6809L310.076 48.2078V26.5271C310.076 22.5311 306.93 19.3853 302.934 19.3853C298.938 19.3853 295.792 22.5311 295.792 26.5271V65.2123L281.764 81.9617C280.573 83.322 280.233 84.6824 280.233 86.2128C280.233 90.0388 283.124 92.7595 287.035 92.7595C288.99 92.7595 290.521 92.0793 291.966 90.4639L342.47 30.1831C343.66 28.8227 344 27.3774 344 25.847C344 22.106 341.109 19.3853 337.198 19.3853Z" fill="#DF4D67"/>
        <path d="M273.856 35.454L273.261 36.0492L256.257 53.2237L245.374 64.1916L240.953 68.6127L233.216 57.2197L261.868 28.1421L262.293 27.632L264.674 25.2513L273.856 35.454Z" fill="#DF4D67"/>
    </svg>
</template>
