<template>
    <svg width="502" height="502" viewBox="0 0 502 502" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_62_999" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="502" height="502">
            <rect width="502" height="502" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_62_999)">
            <path d="M338.979 71.29C337.309 79.95 335.589 88.44 333.809 96.79C223.339 615.92 -90.4414 541.66 65.5786 469.22C212.899 400.82 209.759 115.95 208.579 75.96C208.489 72.91 208.409 71.29 208.409 71.29C208.409 35.23 237.639 6 273.689 6C309.739 6 338.979 35.23 338.979 71.29Z" fill="#DF4D67"/>
            <path d="M308.628 146.3C308.628 146.3 308.318 147.89 307.668 150.87C307.938 149.35 308.248 147.82 308.628 146.3Z" fill="#D96C6C"/>
            <path d="M435.319 177.89C431.599 185.89 427.879 193.71 424.139 201.38C191.369 678.37 -95.1314 530.41 73.7786 497.86C233.269 467.13 299.129 189.96 307.659 150.87C308.309 147.89 308.619 146.3 308.619 146.3C317.339 111.31 352.779 90.0199 387.749 98.7399C422.739 107.46 444.029 142.9 435.309 177.88L435.319 177.89Z" fill="#4DDF7F"/>
            <path d="M384.929 247.76C384.929 247.76 383.949 249.05 382.049 251.44C382.959 250.19 383.919 248.96 384.929 247.76Z" fill="#D96C6C"/>
            <path d="M484.838 331.82C479.008 338.74 472.058 344.16 464.468 348C471.208 342.76 477.988 337.37 484.838 331.82Z" fill="#D96C6C"/>
            <path d="M484.839 370.54C477.989 376.09 471.199 381.48 464.469 386.72C45.7085 712.82 -146.571 453.98 19.4485 499C176.209 541.51 357.219 321.51 382.059 290.15C383.949 287.76 384.939 286.47 384.939 286.47C408.159 258.88 449.339 255.33 476.929 278.54C504.519 301.76 508.069 342.94 484.849 370.53L484.839 370.54Z" fill="#4DDFDF"/>
        </g>
    </svg>
</template>
