
import {PropType, defineComponent} from "vue"
import {Design} from "@/store"

export default defineComponent({
    props: {
        design: {
            type: Object as PropType<Design>,
            required: true
        }
    },

    methods: {
        loadThumbnail(file: string) {
            return require(`@/assets/${file}`)
        },

        openPdfDocument(document: string) {
            const domain = window.location.protocol + '//' + window.location.host
            window.open(`${domain}${document}`, '_blank')
        }
    }
})
