<template>
    <svg viewBox="0 0 55 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_42_520" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="55" height="14">
            <path d="M0 1.69416C0 0.965983 0.609293 0.356689 1.33747 0.356689C2.06565 0.356689 2.67495 0.965983 2.67495 1.69416V5.52825H6.95486V1.69416C6.95486 0.965983 7.56415 0.356689 8.29233 0.356689C9.02051 0.356689 9.62981 0.965983 9.62981 1.69416V11.859C9.62981 12.5871 9.02051 13.1964 8.29233 13.1964C7.56415 13.1964 6.95486 12.5871 6.95486 11.859V8.02487H2.67495V11.859C2.67495 12.5871 2.06565 13.1964 1.33747 13.1964C0.609293 13.1964 0 12.5871 0 11.859V1.69416Z" fill="#303030"/>
            <path d="M19.6756 4.16107C18.353 4.81494 17.4762 6.16728 17.4762 7.92085V9.08C17.4762 10.2391 16.7629 10.8633 15.8712 10.8633C14.9796 10.8633 14.2663 10.2391 14.2663 9.08V4.97841C14.2663 4.29481 13.7164 3.7301 13.018 3.7301C12.3195 3.7301 11.7697 4.29481 11.7697 4.97841V8.99083C11.7697 11.5023 13.5678 13.1816 15.8712 13.1816C16.5697 13.1816 17.2087 13.033 17.7883 12.7506C19.096 12.0967 19.9728 10.7444 19.9728 8.99083V4.97841C19.9728 4.66633 19.8539 4.38398 19.6756 4.16107Z" fill="#303030"/>
            <path d="M21.5779 3.7301C20.8794 3.7301 20.2404 3.87871 19.6757 4.16107C18.3531 4.81494 17.4763 6.16728 17.4763 7.92085V11.9333C17.4763 12.2453 17.5952 12.5277 17.7884 12.7506C19.0961 12.0967 19.9729 10.7444 19.9729 8.99083V7.83169C19.9729 6.67254 20.6863 6.04839 21.5779 6.04839C22.4696 6.04839 23.1829 6.67254 23.1829 7.83169V11.9333C23.1829 12.6317 23.7327 13.1816 24.4312 13.1816C25.1296 13.1816 25.6795 12.6317 25.6795 11.9333V7.92085C25.6795 5.40937 23.8813 3.7301 21.5779 3.7301Z" fill="#303030"/>
            <path d="M26.7495 5.08229C26.7495 4.44327 27.2696 3.92315 27.9087 3.92315H28.2505V2.85317C28.2505 2.15471 28.8003 1.60486 29.4988 1.60486C30.1972 1.60486 30.7471 2.15471 30.7471 2.85317V3.92315H32.5898C33.2288 3.92315 33.749 4.44327 33.749 5.08229C33.749 5.7213 33.2288 6.24143 32.5898 6.24143H30.7471V8.67861C30.7471 10.1647 31.5644 10.878 32.5898 10.878C33.2288 10.878 33.749 11.3981 33.749 12.0372C33.749 12.6762 33.2288 13.1963 32.5898 13.1963C30.1824 13.1963 28.2653 11.4427 28.2653 8.69347V6.25629H27.9235C27.2845 6.25629 26.7644 5.73616 26.7644 5.09715L26.7495 5.08229Z" fill="#303030"/>
            <path d="M35.978 9.95678C36.3049 9.95678 36.5278 10.0608 36.721 10.2243C37.0926 10.5215 37.36 10.9525 37.9396 10.9525C38.2814 10.9525 38.5638 10.6701 38.5638 10.3283C38.5638 9.76359 37.9396 9.58526 37.2114 9.33263C36.1712 8.97597 34.908 8.50042 34.908 6.56852C34.908 5.02299 36.2306 3.7301 37.9396 3.7301C39.4554 3.7301 40.9712 4.60689 40.9712 5.63229C40.9712 6.2713 40.4511 6.79143 39.8121 6.79143C39.604 6.79143 39.4406 6.73199 39.2771 6.67254C38.8164 6.43477 38.4895 5.95922 37.9396 5.95922C37.5978 5.95922 37.3155 6.24158 37.3155 6.58338C37.3155 7.08865 37.9545 7.23725 38.7124 7.48989C39.7824 7.83169 41.0604 8.35182 41.0604 10.2391C41.0604 11.8441 39.6932 13.1816 37.9396 13.1816C36.3198 13.1816 34.8188 12.1859 34.8188 11.1159C34.8188 10.4769 35.339 9.95678 35.978 9.95678Z" fill="#303030"/>
            <path d="M42.8439 1.605C42.8439 0.90654 43.3937 0.356689 44.0922 0.356689C44.7906 0.356689 45.3405 0.90654 45.3405 1.605V11.9481C45.3405 12.6466 44.7906 13.1964 44.0922 13.1964C43.3937 13.1964 42.8439 12.6466 42.8439 11.9481V1.605ZM46.0389 9.3772C45.8458 9.13943 45.6971 8.82735 45.6971 8.47069C45.6971 8.2032 45.816 7.84654 46.0241 7.60877L48.9368 4.1462C49.1894 3.86384 49.4569 3.73009 49.7987 3.73009C50.4823 3.73009 50.9876 4.20564 50.9876 4.87438C50.9876 5.14187 50.9133 5.39451 50.7201 5.61742L48.4315 8.32209L50.7795 11.1159C50.9727 11.3537 51.0768 11.6509 51.0768 11.9184C51.0768 12.6317 50.4675 13.1667 49.8136 13.1667C49.4718 13.1667 49.1003 13.0032 48.8625 12.7209L46.0241 9.34748L46.0389 9.3772Z" fill="#303030"/>
            <path d="M52.1467 1.42664C52.1467 0.639015 52.7857 0 53.5734 0C54.361 0 55 0.639015 55 1.42664C55 2.21426 54.361 2.85328 53.5734 2.85328C52.7857 2.85328 52.1467 2.21426 52.1467 1.42664ZM52.3251 4.99323C52.3251 4.29477 52.8749 3.74493 53.5734 3.74493C54.2718 3.74493 54.8217 4.29477 54.8217 4.99323V11.9481C54.8217 12.6466 54.2718 13.1964 53.5734 13.1964C52.8749 13.1964 52.3251 12.6466 52.3251 11.9481V4.99323Z" fill="#303030"/>
        </mask>
        <g mask="url(#mask0_42_520)">
            <rect x="-10" y="-2" width="73.3374" height="20.9844" fill="url(#paint0_linear_42_520)"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_42_520" x1="-2.4283" y1="-15.0882" x2="71.7744" y2="18.9844" gradientUnits="userSpaceOnUse">
                <stop/>
                <stop offset="1" stop-color="#1E3050"/>
            </linearGradient>
        </defs>
    </svg>
</template>
